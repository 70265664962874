<template>
	<div>
		<div class='a-booking a-content a-faq a-faq-category'>
			<div class='app-container app-container-fluid a-container'>
				<div class="a-faq-breadcrumbs tablet-hide">
					<ul class='a-breadcrumbs-list'>
						<li class='a-breadcrumbs-item active'>
							<router-link :to='$i18nRoute({name: "faq-hc"})' class='a-breadcrumbs-link link'>FAQ</router-link>
							<v-icon icon='arrow-down' size='9' class='a-breadcrumbs-icon' />
						</li>
						<li class='a-breadcrumbs-item'>
							<span class='a-breadcrumbs-link'>{{title}}</span>
						</li>
					</ul>
				</div>
				<div class='a-booking-container'>
					<div class='a-booking-content'>
						<div class='a-booking-box'>
							<div class="a-faq-breadcrumbs tablet-show">
								<ul class='a-breadcrumbs-list'>
									<li class='a-breadcrumbs-item active'>
										<router-link :to='$i18nRoute({name: "faq-hc"})' class='a-breadcrumbs-link link'>FAQ</router-link>
										<v-icon icon='arrow-down' size='9' class='a-breadcrumbs-icon' />
									</li>
									<li class='a-breadcrumbs-item'>
										<span class='a-breadcrumbs-link'>{{title}}</span>
									</li>
								</ul>
							</div>
							<p class='a-heading-1'>{{title}}</p>
							<div class="row">
								<div class="col-6" v-for='(item, i) in items' :key='i'>
									<ul class='a-faq-list'>
										<li class='a-faq-list-item'
											v-for='(el, i) in item'
											:key='i'
										>
											<router-link :to='$i18nRoute({name: "faq-single", params: {categoryId: el.category_id, id: el.id}})' class='a-faq-list-link'>{{el.question}}</router-link>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
					<div class="a-booking-banner"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { getFaqList } from '@/services/request';

	export default {
		data: () => ({
			title: '',
			search: '',
			items: [],
			loading: false,
			loading_btn: false
		}),
		created() {
			this.getFaqList();
		},
		methods: {
			getFaqList() {
				this.loading = true;
				getFaqList({
					search: this.search
				}).then(res => {
					let filterCategory = res.data.filter(el => el.category_id == this.$route.params.categoryId);
					if(filterCategory === 0) return;
					this.items = this.$_.groupBy(filterCategory, 'category_id');
					this.title = filterCategory[0].category
				}).finally(() => this.loading = false, this.loading_btn = false)
			},
			updateSearch() {
				this.loading_btn = true;
				this.getFaqList();
			}
		}
	}
</script>

<style lang="scss">
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.a-faq-category {
		@include maxw( $grid-breakpoints-sm - 1 ) {
			.a-heading-1 {
				margin-bottom: 25px;
			}
			.a-faq-list {
				padding: 0;
			}
		}
	}
</style>
